.header-info,
.header-account {
	float: left;
	width: 50%;
	background: color('blue');
	color: color('white');
	text-align: center;
	font-size: 13px;
    line-height: 50px;
}

.header-info {
	border-right: 1px solid color('blue', 'light');

    span, a {
        color: color('white');
        display: inline-block;
        vertical-align: middle;
    }

    span {
        font-size: 20px;
        margin-right: 10px;
    }

}

.header-account {

    &.active {
        
        .icon-arrow {
            transform: rotate(180deg);
        }

    }

    span {
        font-size: 20px;          
    }

    span, .hello {
        display: inline-block;
        vertical-align: middle;
    }

    .icon-arrow {
        font-size: 10px;
        margin-left: 10px;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;        
    }

    .box {
        @include arrow();
        width: 200%;
        left: -100%;
        border-top: 1px solid color('yellow');
        color: color('gray', 'dark');
        padding: 20px 20px 0 20px;
        background: color('white');

        a {
            display: block;
            margin: 13px 0;
            line-height: 24px;

            &:last-child {
                margin-bottom: 0;
            }

            &.button {
                margin: 0;
            }

        }

    }

}