.cms-index-index {

	.main-container {
    	@include outer-container(100%);

    	.container, .main {
    		@include span-columns(12);
			padding: 0;
			
			.banner-mobille--full {

				@include span-columns(12);
			}
    	}

	}

	.page-title {
		@extend .no-display;
	}

	.top-banners {
		@include span-columns(12);
	}

	@import "components/home/bar.scss";
	@import "components/home/showcase.scss";
}