%input-style {
    font-family: $font;
    padding: 10px;
    font-size: 16px;
    border: 1px solid color('gray');
    color: color('gray');
    width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    border-radius: 0;
}

%label-style {
    display: block;
    font-size: 16px;
    margin-bottom: 10px;
}

%advice-style {
    color: color('red');
    line-height: 20px;
    margin: 5px 0;
    font-size: 12px;
    text-transform: uppercase;    
}

%disabled-style {
    opacity: 0.5;

    & + label {
        opacity: 0.5;
    }

}

%controll-style {
    display: none;

    & + label {
        cursor: pointer;
        padding-left: 30px;
        margin-bottom: 15px;
        font-size: 16px;
        vertical-align: top;
        position: relative;
        line-height: 20px;

        &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
        }

    }

    &:checked + label {

        &:before {
            content: '';
            position: absolute;
            top: 2px;
            left: 2px;
        }

    }

}

%checkbox-style {

    & + label {

        &:after {
            height: 20px;
            width: 20px;
            border: 2px solid color('gray');
        }

    }

    &:checked + label {

        &:before {
            background: color('blue');
            left: 4px;
            top: 4px;
            height: 12px;
            width: 12px;
            z-index: 1;
        }

        &:after{
            background: color('white');            
            z-index: 0;
        }

    }

}

%radio-style {

    & + label {

        &:after {
            height: 20px;
            width: 20px;
            border: 1px solid color('gray');
            @include border-radius(50%, 50%, 50%, 50%);
        }

    }

    &:checked + label {

        &:before {
            background: color('gray');
            left: 6px;
            top: 6px;
            height: 8px;
            width: 8px;
            z-index: 1;
            @include border-radius(50%, 50%, 50%, 50%);
        }

        &:after {
            background: rgba(color('blue'), 0.1);
            z-index: 0;
        }

    }

}

%file-style {
    padding: 10px;
}

input, select, textarea {
    @extend %input-style;

    &.validation-failed {
        border-color: color('red');
    }

    &:disabled {
        @extend %disabled-style;
    }

    &[type='checkbox'],
    &[type='radio'] {
        @extend %controll-style;

        &:disabled {
            @extend %disabled-style;
        }

    }

    &[type='checkbox'] {
        @extend %checkbox-style;
    }

    &[type='radio'] {
        @extend %radio-style;
    }

    &[type='file'] {
        @extend %file-style;
    }

}

select {
    background: color('white') url('../images/bg-select.png') no-repeat center right;
    padding-right: 20px;
}

.form-list {

    li {
        @include span-columns(12);

        &.fields {

            .field {
                @include span-columns(5.7);

                &:last-child,
                &:nth-child(2) {
                    float: right;
                    margin-right: 0;
                }

                &.field-cpf {
                    float: left;
                }

                &.field-cnpj {
                    float: right;
                }                

            }

        }

        em {
            @extend .no-display;
        }

        label {
            @extend %label-style;
        }

        &.street-2 {
            @include span-columns(2);
        }

        &.street-3 {
            @include span-columns(9.4);
            float: right;
            margin-right: 0;
        }

        &.type-person {
            @include span-columns(12);
            margin: 20px 0;

            label, .input-box {
                @include span-columns(12);
                margin-bottom: 0;
            }

            label {
                margin-bottom: 15px;                
            }

            .input-box {

                input[type='radio'] {
                    @extend %checkbox-style;
                }

                label {
                    text-transform: uppercase;

                    &:before,
                    &:after {
                        @include border-radius(0, 0, 0, 0);
                    }

                }

            }

        }  

        &.dob {

            label {
                @include span-columns(12);
            }

            .customer-dob {
                @include span-columns(12);

                .dob-day,
                .dob-month,
                .dob-year {
                    width: 26%;
                    margin-right: 10%;
                    display: inline-block;

                    input {
                        text-align: center;
                    }

                }

                .dob-day {
                    margin-right: 10.4%;
                }

                .dob-year {
                    margin-right: 0;
                }

            }

        }    

        .customer-name-pj {

            .field {
                @include span-columns(12);
            }

        }
        
        .postcode {

            label {
                position: relative;

                a {
                    position: absolute;
                    right: 0;
                    color: color('blue');
                    text-decoration: underline;
                    font-size: 12px;
                }

            }

        }        

    }

}

.input-box {
    margin-bottom: 20px;

    .validation-advice {
        @extend %advice-style;
    }

}
