.customer-account {

	.back-link {
		@extend .no-display;
	}

	.col-right {
		@include span-columns(12);
		margin: 0 0 40px 0;
		@import "components/account/sidebar.scss";
	}

	.page-title {
		@include span-columns(12);
		padding: 20px 0;
		margin-bottom: 20px;
		margin-top: 0;
		border-bottom: 4px solid color('gray', 'light');

		h1 {
			font-size: 18px;
			color: color('blue');
			padding-bottom: 0;
		}

	}

	.content {
		@include span-columns(12);
		margin: 20px 0;
		color: color('black');

		h2, h3 {
			font-size: 16px;
			text-transform: uppercase;
			color: color('blue');
			font-weight: bold;
			letter-spacing: 2px;
			margin-bottom: 20px;
		}

		.col-1, .col-2 {
			@include span-columns(12);
		}

		.box {
			@include span-columns(12);
			margin: 0 0 20px 0;
			border-bottom: 2px solid color('blue');			

			.box-title {
				@include span-columns(12);

				h2 {
					@include span-columns(8);
					padding-top: 0;
					padding-bottom: 0;					
				}

				a {
					@include span-columns(4);
					text-align: right;
					font-size: 16px;
					color: color('blue');
					font-weight: bold;
					text-decoration: underline;					
				}

			}

			.box-content {
				@include span-columns(12);
				margin: 20px 0;
				font-size: 16px;
				line-height: 20px;
			}
		}

		.phone-layout {

			.input-box {

				border: 1px solid color('gray');
				height: 48px;

				select {

					@include span-columns(4);
					margin-bottom: 20px;
					border: none;
					background-color: color('gray', 'light');
					width: 27%;
					padding: 13px 20px;
				}

				input {

					@include span-columns(8);
					margin-bottom: 20px;
					border: none;
					padding-bottom: 11px;
				}
			}
		}

	}

	address {

		strong {
			display: block;
		}

	}

	@import "components/account/dashboard.scss";
	@import "components/account/information.scss";
	@import "components/account/address.scss";
	@import "components/account/orders.scss";
}